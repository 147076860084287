<template>
  <div v-frag>
    <page-title title="order.orderDetails"></page-title>
  </div>
</template>

<script>
import PageTitle from "../../components/titles/PageTitle.vue";

export default {
  name: 'InnerOrderDetails',
  components: {
    PageTitle,
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
