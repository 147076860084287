<template>
  <v-container class="mt-5" :fluid="fluid">
    <h1 class="mb-3 font-weight-regular">
      {{ $t(`${title}`) }}
    </h1>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: String,
    fluid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
